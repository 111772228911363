<template>
  <div class="pad">
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">AAA级企业信用等级证书</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (11).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">AAA级系统集成行业诚信单位</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (12).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">AAA级诚信供应商</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (5).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span
        ><span class="text">AAA级诚信经营示范单位</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (6).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">AAA级质量、服务诚信单位</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (7).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span
        ><span class="text">AAA级立信单位</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (8).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">AAA级重合同守信用企业</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (13).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">AAA级资信等级证书</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (14).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span
        ><span class="text">环境管理体系认证证书</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (15).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span
        ><span class="text">职业健康安全管理体系认证证书</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (16).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span
        ><span class="text">质量管理体系认证证书</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (17).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span
        ><span class="text">增值电信业务经营许可证</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (1).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span
        ><span class="text">增值电信业务经营许可证</span>
      </div>
      <div><img class="zs-img" src="@/assets/images/zs (2).png" alt="" /></div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">增值电信业务经营许可证</span>
      </div>
      <div>
        <img class="zs-img ms-img" src="@/assets/images/zs (3).png" alt="" />
      </div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">计算机软件著作权登记证书</span>
      </div>
      <div>
        <img class="zs-img ms-img" src="@/assets/images/zs (4).png" alt="" />
      </div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">诚信职业经理人证书</span>
      </div>
      <div>
        <img class="zs-img ms-img" src="@/assets/images/zs (9).png" alt="" />
      </div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">诚信企业家证书</span>
      </div>
      <div>
        <img class="zs-img ms-img" src="@/assets/images/zs (10).png" alt="" />
      </div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">信息系统建设和服务能力等级证书 信息技术服务标准符合性证书</span>
      </div>
      <div>
        <img class="zs-img ms-img" src="@/assets/images/zs (18).png" alt="" />
      </div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">信息安全服务资质认证证书</span>
      </div>
      <div>
        <img class="zs-img ms-img" src="@/assets/images/zs (19).png" alt="" />
      </div>
    </div>
    <div class="box">
      <div>
        <span class="lan"></span><span class="text">中国电子信息行业联合会证书</span>
      </div>
      <div>
        <img class="zs-img ms-img" src="@/assets/images/zs (20).png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.pad {
  padding-bottom: 0.5rem;
  margin: auto;
}
.box {
  margin-left: 0.5rem;
  padding-top: 0.4rem;
}
.lan {
  display: inline-block;
  width: 0.18rem;
  height: 0.18rem;
  background-color: #08467c;
  border-radius: 50%;
  position: relative;
}
.lan::before {
  display: block;
  content: '';
  width: 0.01rem;
  height: 5rem;
  background-color: #959595;
  clear: both;
  position: absolute;
  left: 0.08rem;
  top: -1rem;
  z-index: -1;
}
.zs-img {
  width: 2.2rem;
  height: 3.03rem;
  padding-left: 0.4rem;
}
.text {
  padding-left: 0.3rem;
  font-size: 0.28rem;
  font-weight: bold;
  color: #333333;
}
.ms-img {
  width: auto;
  height: 3.06rem;
}
</style>
